import React from 'react';
import { Layout, Button, theme } from 'antd';
import Carousel from "../components/carousel";
import { useNavigate } from "react-router-dom";
import 'mapbox-gl/dist/mapbox-gl.css';
import './pagesStyle/welcomePageStyles.css';

const { Header, Content, Footer } = Layout;

function WelcomePage() {
  const navigate = useNavigate();

  const onClickRegister = () => {
    navigate("/register");
  };

  const onClickLogin = () => {
    navigate("/login");
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout className="welcomePage">
      <Header className="welcomePage-header">
        <div className="welcomePage-logo" />
        <div className="welcomePage-buttonContainer">
          <Button onClick={onClickLogin}>Iniciar sesión</Button>
          <Button onClick={onClickRegister}>Registrar</Button>
        </div>
      </Header>
      <Content
        style={{
          padding: '0 48px',
        }}
      >
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h1>Bienvenido(a)</h1>
          <div className="welcomePage-textSection">
            <p>
              Bienvenido a kurro la plataforma que conecta estudiantes y freelancers con restaurantes, si eres un estudiante/freelancer durante el registro te pedimos que después de darle al botoón para continuar esperes a que cargue la siguiente página porque que necesitas completar los datos que te solicitaran para que puedas obtener tu pago.
             <strong> 
              NOTA: Cuando te pidan url del sitio web puedes ingresar la url de tu perfil de tu red social favorita y aunque veas que mencionan empresa en realidad nos referimos a ustedes los estudiantes/freelancers esto se ve así debido a un tema de la pasarela de pago manejada
             </strong>
            </p>
          </div>
          <Carousel className="welcomePage-carousel" />
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        © 2024 KURRO. Todos los derechos reservados.
      </Footer>
    </Layout>
  );
}

export default WelcomePage;
