// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* workerProfileStyles.css */

.button-hover:hover {
    background-color: white !important;
    color: var(--color-red) !important;
    border-color: var(--color-red) !important;
  }
  
  .textStyle {
    text-indent: 20px;
  }
  
  .buttonContainerStyle {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .rightButtonContainerStyle {
    display: flex;
    justify-content: flex-end;
  }
  
  .saveButton {
    background-color: var(--color-red) !important;
    border-color: var(--color-red) !important;
    color: white !important;
  }
  
  .saveButton:hover {
    background-color: white !important;
    color: var(--color-red) !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/styles/workerProfileStyles.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B;IACI,kCAAkC;IAClC,kCAAkC;IAClC,yCAAyC;EAC3C;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,6CAA6C;IAC7C,yCAAyC;IACzC,uBAAuB;EACzB;;EAEA;IACE,kCAAkC;IAClC,kCAAkC;EACpC","sourcesContent":["/* workerProfileStyles.css */\n\n.button-hover:hover {\n    background-color: white !important;\n    color: var(--color-red) !important;\n    border-color: var(--color-red) !important;\n  }\n  \n  .textStyle {\n    text-indent: 20px;\n  }\n  \n  .buttonContainerStyle {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n  \n  .rightButtonContainerStyle {\n    display: flex;\n    justify-content: flex-end;\n  }\n  \n  .saveButton {\n    background-color: var(--color-red) !important;\n    border-color: var(--color-red) !important;\n    color: white !important;\n  }\n  \n  .saveButton:hover {\n    background-color: white !important;\n    color: var(--color-red) !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
