// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatarButton {
    background-color: var(--color-red) !important;
    border-color: var(--color-red) !important;
    color: white !important;
  }
  
  .avatarButton:hover {
    background-color: white !important;
    color: var(--color-red) !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/styles/avatarStyles.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,yCAAyC;IACzC,uBAAuB;EACzB;;EAEA;IACE,kCAAkC;IAClC,kCAAkC;EACpC","sourcesContent":[".avatarButton {\n    background-color: var(--color-red) !important;\n    border-color: var(--color-red) !important;\n    color: white !important;\n  }\n  \n  .avatarButton:hover {\n    background-color: white !important;\n    color: var(--color-red) !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
