import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import type { DropdownProps, MenuProps } from 'antd';
import { Dropdown, Space, Menu } from 'antd';

interface DropDownMenuProps {
  onSelect: (selected: string) => void;
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({ onSelect }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const selectedText = e.domEvent.currentTarget.textContent;
    if (selectedText) {
      setSelected(selectedText);
      onSelect(selectedText);
    }
    setOpen(false);
  };

  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen) => {
    setOpen(nextOpen);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Soy estudiante o free lancer',
      key: '1',
    },
    {
      label: 'Soy un negocio',
      key: '2',
    }
  ];

  return (
    <div>
      <Dropdown
        menu={{
          items,
          onClick: handleMenuClick,
        }}
        onOpenChange={handleOpenChange}
        open={open}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {selected ? selected : 'Seleccione una opción para continuar'}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default DropDownMenu;
