import React, { useState, useEffect } from 'react';
import { Descriptions, Input, Button, Rate, Modal, message } from 'antd';
import axios from 'axios';
import ReviewPanel from '../userReviewsPanel';
import AvatarUploader from '../avatarUploader';
import WorkerSurveyPanel from '../reviewSurveyPanelWorker';
import '../styles/workerProfileStyles.css';
import { API_URL } from '../../config/credentials';

const { TextArea } = Input;

interface WorkerProfileViewProps {
  onFullnameChange: (fullname: string) => void;
}

const WorkerProfileView: React.FC<WorkerProfileViewProps> = ({ onFullnameChange }) => {
  const [description, setDescription] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(1); // Rating
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isReviewPanelVisible, setIsReviewPanelVisible] = useState<boolean>(false);
  const [isSurveyPanelVisible, setIsSurveyPanelVisible] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [fullname, setFullname] = useState<string>('');
  const [finishedJobs, setFinishedJobs] = useState<any[]>([]);
  const [restaurantName, setRestaurantName] = useState<string>('');
  const [userType, setUserType] = useState<string>('');
  const [tempDescription, setTempDescription] = useState<string>('');
  const [tempImageUrl, setTempImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchWorkerData = async () => {
      const storedUserId = localStorage.getItem('userId');
      const storedUserType = localStorage.getItem('userType');
      setUserType(userType);
      console.log(`Fetching data for userId: ${storedUserId}`);

      if (storedUserId) {
        try {
          const response = await axios.get(`${API_URL}/workers/${storedUserId}`);
          const workerData = response.data;
          console.log('Worker data fetched:', workerData);

          setDescription(workerData.worker_resume);
          setImageUrl(workerData.profile_picture);
          setEmail(workerData.email);
          setPhone(workerData.phone);
          setFullname(workerData.fullname);
          onFullnameChange(workerData.fullname);
          setTempDescription(workerData.worker_resume);
          setTempImageUrl(workerData.profile_picture);

          // Fetch average rating
          const ratingResponse = await axios.get(`${API_URL}/reviews/worker/average/${workerData.fullname}`);
          const averageRating = parseFloat(ratingResponse.data.average_rating);
          setRating(averageRating);

          // Fetch finished jobs
          const jobsResponse = await axios.get(`${API_URL}/workers/${storedUserId}/finished_jobs`);
          console.log('Finished jobs fetched:', jobsResponse.data);
          setFinishedJobs(jobsResponse.data);

          if (jobsResponse.data.length > 0) {
            setRestaurantName(jobsResponse.data[0].company_name);
          }
        } catch (error) {
          console.error('Error fetching worker data:', error);
        }
      }
    };

    fetchWorkerData();
  }, [onFullnameChange]);

  const handleSave = async () => {
    const storedUserId = localStorage.getItem('userId');
    console.log(`Saving data for userId: ${storedUserId}`);

    try {
      const updateData = {
        profile_picture: tempImageUrl,
        worker_resume: tempDescription,
      };
      console.log('Update data:', updateData);

      const response = await axios.put(`${API_URL}/workers/${storedUserId}`, updateData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        message.success('Perfil actualizado correctamente!');
        setDescription(tempDescription);
        setImageUrl(tempImageUrl);
        setIsEditing(false);
      }
    } catch (error) {
      message.error('Error porfavor intente de nuevo');
    }
  };

  const handleCancel = () => {
    console.log('Cancelling edit');
    setTempDescription(description);
    setTempImageUrl(imageUrl);
    setIsEditing(false);
  };

  const ratingTooltips = [
    'Nuevo aspirante',
    'Necesita mejorar',
    'Se le dificulta un poco',
    'Regular',
    'Muy Bueno',
    'Excelente'
  ];

  return (
    <>
      <Descriptions title="Información de contacto" layout="vertical" column={1}>
        <Descriptions.Item label="Foto de perfil">
          <AvatarUploader imageUrl={isEditing ? tempImageUrl : imageUrl} setImageUrl={setTempImageUrl} isEditing={isEditing} />
        </Descriptions.Item>
        <Descriptions.Item label="Nombre completo">
          <span className="textStyle">{fullname}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Teléfono">
          <span className="textStyle">34 {phone}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Correo electrónico">
          <span className="textStyle">{email}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Calificación del usuario">
          <Rate 
            disabled 
            value={rating}
          />
          <span style={{ marginLeft: 8 }}>{rating} - {ratingTooltips[Math.round(rating)]}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Descripción" span={2}>
          <TextArea
            value={isEditing ? tempDescription : description}
            onChange={(e) => setTempDescription(e.target.value)}
            placeholder="Compártele al mundo quién eres y tu experiencia en el campo laboral..."
            autoSize={{ minRows: 3, maxRows: 12 }}
            disabled={!isEditing}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className="buttonContainerStyle">
        <div className="rightButtonContainerStyle">
          <Button className="button-hover" onClick={() => setIsEditing(true)} style={{ marginRight: '10px' }}>
            Editar perfil
          </Button>
          {isEditing && (
            <>
              <Button className="button-hover" onClick={handleCancel} style={{ marginRight: '10px' }}>
                Cancelar
              </Button>
              <Button type="primary" className="button-hover saveButton" onClick={handleSave}>
                Guardar cambios
              </Button>
            </>
          )}
        </div>
      </div>
      <Modal
        title="Comentarios a tu perfil"
        open={isReviewPanelVisible}
        onCancel={() => setIsReviewPanelVisible(false)}
        footer={null}
      >
        <ReviewPanel />
      </Modal>
      <Modal
        title="¡Cuéntanos tu experiencia!"
        open={isSurveyPanelVisible}
        onCancel={() => setIsSurveyPanelVisible(false)}
        footer={null}
      >
        <WorkerSurveyPanel onCancel={() => setIsSurveyPanelVisible(false)} userFullName={fullname} restaurantName={restaurantName} userType={userType}/>
      </Modal>
    </>
  );
};

export default WorkerProfileView;
