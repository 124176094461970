import { Carousel as AntdCarousel } from 'antd';
import { CSSProperties } from 'react';
import imgUno from '../assets/img-uno.jpeg';
import imgDos from '../assets/img-dos.jpeg';
import imgTres from '../assets/img-tres.jpeg';
import imgCuatro from '../assets/img-cuatro.jpeg';

const imageContainerStyle: CSSProperties = {
  width: '100%',
  height: '300px', // Set a fixed height for the images
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
};

const imageStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Ensure the image covers the container proportionally
};

const FimageStyle:CSSProperties = {
  width: '100%',
  maxHeight:'60%',
  objectFit: 'cover', // Ensure the image covers the container proportionally
}

const arrowStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  padding: '10px',
  cursor: 'pointer',
  zIndex: 2,
};

const prevArrowStyle: CSSProperties = {
  ...arrowStyle,
  left: '0',
};

const nextArrowStyle: CSSProperties = {
  ...arrowStyle,
  right: '0',
};

function Carousel() {
  return (
    <div className="carousel-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto', position: 'relative' }}>
      <AntdCarousel autoplay arrows infinite={false} prevArrow={<div style={prevArrowStyle}>{"<"}</div>} nextArrow={<div style={nextArrowStyle}>{">"}</div>}>
        <div style={imageContainerStyle}>
          <img src={imgUno} style={imageStyle} />
        </div>
        <div style={imageContainerStyle}>
          <img src={imgDos} style={imageStyle} />
        </div>
        <div style={imageContainerStyle}>
          <img src={imgTres} style={imageStyle} />
        </div>
        <div style={imageContainerStyle}>
          <img src={imgCuatro} style={FimageStyle} />
        </div>
      </AntdCarousel>
    </div>
  );
}

export default Carousel;
