import React, { useEffect, useState } from 'react';
import { Avatar, List, Skeleton, Modal, message, Button } from 'antd';
import axios from 'axios';
import UserInfo from './userInfo';
import '../styles/workerListStyles.css';
import { API_URL } from '../../config/credentials';

interface DataType {
  dni: string;
  email: string;
  phone: string;
  age?: number;
  fullname: string;
  profile_picture?: string;
  worker_resume?: string;
  rating?: number;
  loading?: boolean;
}

interface UserListViewProps {
  jobId: number | undefined;
}

const UserListView: React.FC<UserListViewProps> = ({ jobId }) => {
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState<DataType[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<DataType | null>(null);

  useEffect(() => {
    if (jobId !== undefined && !isNaN(jobId)) {
      fetchWorkers();
    } else {
      message.error('Invalid jobId');
    }
  }, [jobId]);

  const fetchWorkers = async () => {
    setInitLoading(true);
    try {
      console.log(`Fetching workers for jobId: ${jobId}`);
      const response = await axios.get(`${API_URL}/workers/jobs/${jobId}`);
      console.log('Workers data received:', response.data);
      if (response.data.message) {
        message.warning(response.data.message);
        setData([]);
      } else {
        setData(Array.isArray(response.data) ? response.data : [response.data]);
      }
    } catch (error) {
      console.error('Error fetching workers:', error);
      message.error('Error loading data');
    } finally {
      setInitLoading(false);
    }
  };

  const handleItemClick = (item: DataType) => {
    console.log('Item clicked:', item);
    setSelectedUser(item);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    console.log('Modal closed');
    setModalVisible(false);
    setSelectedUser(null);
  };

  const handleSelectClick = async () => {
    if (selectedUser && jobId) {
      console.log('Sending data to server:', {
        worker_dni: selectedUser.dni,
        job_status: true
      });
      try {
        // Update the job
        await axios.put(`${API_URL}/jobs/${jobId}`, {
          worker_dni: selectedUser.dni,
          job_status: false
        });
        message.success(`Trabajador ${selectedUser.fullname} seleccionado exitosamente`);
  
        // Delete all job applications for the job
        await axios.delete(`${API_URL}/job_applications/job/${jobId}`);
        message.success('Todas las solicitudes de trabajo han sido eliminadas');
  
        setModalVisible(false);
      } catch (error) {
        console.error('Error updating job or deleting job applications:', error);
        message.error('Error updating job or deleting job applications');
      }
    }
  };  

  const mapUserToUserInfo = (user: DataType | null) => {
    if (!user) return null;

    const nameParts = user.fullname.split(' ');
    const first = nameParts.slice(0, -1).join(' ');
    const last = nameParts.slice(-1).join(' ');

    const mappedUser = {
      name: {
        title: '',
        first: first || '',
        last: last || ''
      },
      picture: {
        large: user.profile_picture || ''
      },
      dob: {
        age: user.age ?? 0
      },
      phone: user.phone || '',
      email: user.email || '',
      rating: user.rating ?? 0,
      description: user.worker_resume || ''
    };

    console.log('Mapped user:', mappedUser);
    return mappedUser;
  };

  return (
    <>
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item onClick={() => handleItemClick(item)}>
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar src={item.profile_picture} />}
                title={item.fullname}
                description={`Edad: ${item.age}`}
              />
            </Skeleton>
          </List.Item>
        )}
      />
      <Modal
        open={modalVisible}
        title="Perfil del usuario"
        footer={null}
        onCancel={handleModalClose}
      >
        <UserInfo visible={modalVisible} onClose={handleModalClose} user={mapUserToUserInfo(selectedUser)} />
        <div className="modal-footer-button">
          <Button type="primary" className='select-worker-button' onClick={handleSelectClick}>
            Seleccionar trabajador
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UserListView;
