import React from 'react';
import { Card, theme } from 'antd';

interface ContentCardProps {
  title: string;
  description: string;
  onClick: () => void;
}

const ContentCard: React.FC<ContentCardProps> = ({ title, description, onClick }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Card
      title={title || 'No Title'}
      bordered={true}
      style={{
        width: '100%',
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
        borderColor: '#d9d9d9',
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <p>{description || 'No Description'}</p>
    </Card>
  );
};

export default ContentCard;
