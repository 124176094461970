import { useState, useEffect } from 'react';

interface UserLocation {
  latitude: number;
  longitude: number;
}

const useUserLocation = () => {
  const [location, setLocation] = useState<UserLocation | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by your browser');
      return;
    }

    const handleSuccess = (position: { coords: { latitude: any; longitude: any; }; }) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });

      // Reload the page when location is obtained
      if (!localStorage.getItem('locationObtained')) {
        localStorage.setItem('locationObtained', 'true');
        window.location.reload();
      }
    };

    const handleError = (error: { message: string | null }) => {
      setError(error.message);
    };

    // Request location access
    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  }, []);

  return { location, error };
};

export default useUserLocation;