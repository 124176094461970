import {createBrowserRouter} from "react-router-dom";
//Pages
import RegisterPage from "../pages/registerPage";
import WelcomePage from "../pages/welcomePage";
import HomePage from "../pages/homePage";
import LoginPage from "../pages/loginPage";
  
const router = createBrowserRouter([
    {
      path: "/",
      element: <WelcomePage/>,
    },
    {
      path: "/register",
      element: <RegisterPage/>,
    },
    {
      path: "/login",
      element: <LoginPage/>,
    },
    {
        path:"/welcome",
        element: <WelcomePage/>
    },
    {
      path:"/home",
      element: <HomePage/>
    }
]);

export default router;