import React, { useState, useEffect } from 'react';
import { Divider, Row, Col, Button, Modal, message, Popconfirm, Pagination } from 'antd';
import axios from 'axios';
import ContentCard from '../contentCard';
import '../styles/jobsLayoutStyles.css';
import UserListView from './userListView';
import { format, parseISO, addHours, set } from 'date-fns';
import { es } from 'date-fns/locale';
import WorkerUserInfo from './workerUserInfo';
import WorkersList from './workersList';
import WorkerSurveyPanel from '../reviewSurveyPanelWorker';
import ReviewPanel from '../userReviewsPanel';
import { API_URL, REACT_APP_STRIPE_PUBLIC_KEY } from '../../config/credentials';
//componenets
import PayWorker from '../payment/payWorker/PayWorker';

interface JobData {
  id: number;
  worker_dni: string;
  restaurant_id: number;
  job_title: string;
  job_description: string;
  job_start_date: string;
  job_end_date: string;
  job_start_hour: string;
  job_end_hour: string;
  job_payment: number;
  job_status: boolean;
  job_finished: boolean;
}

interface User {
  name: {
    title?: string;
    first: string;
    last: string;
  };
  picture?: {
    large?: string;
  };
  dob?: {
    age?: number;
  };
  phone: string;
  email: string;
  rating: number;
  description?: string;
}

interface ManageJobsLayoutProps {
  handleCardClick: (card: JobData) => void;
  colorBgContainer: string;
  borderRadiusLG: string;
  colorBorder: string;
  restaurantId?: number;
  restaurantName: string;
}

const ManageJobsLayout: React.FC<ManageJobsLayoutProps> = ({
  handleCardClick,
  colorBgContainer,
  borderRadiusLG,
  colorBorder,
  restaurantId,
  restaurantName,
}) => {
  const [selectedCard, setSelectedCard] = useState<JobData | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isWorkerModalVisible, setIsWorkerModalVisible] = useState(false);
  const [isAspirantsModalVisible, setIsAspirantsModalVisible] = useState(false);
  const [jobData, setJobData] = useState<JobData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [workerInfo, setWorkerInfo] = useState<User | null>(null);
  const [isSurveyPanelVisible, setIsSurveyPanelVisible] = useState<boolean>(false);
  const [fullname, setFullname] = useState<string>('');
  const [userType,setUserType] = useState<string>('');
  const [workerDni, setWorkerDni] = useState('');
  const [workerPayModal,setWorkerPayModal] = useState<boolean>(false);
  //Payment variables
  const [currentClientSecret,setCurrentClientSecret] = useState('');
  const [workerStripeAccountID,setWorkerStripeAccountID] = useState('');
  //Payment modal

  const pageSize = 6;

  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    console.log(storedUserType);
    const fetchJobs = async () => {
      if (!restaurantId) {
        console.error('restaurantId is not defined');
        return;
      }

      try {
        console.log(`Fetching jobs for restaurantId: ${restaurantId}`);
        const response = await axios.get(`https://backend.kurro.es/jobs/restaurant/${restaurantId}`);
        const data = response.data;
        console.log('Jobs data received:', data);

        if (Array.isArray(data)) {
          setJobData(data);
        } else if (typeof data === 'object' && data !== null) {
          setJobData([data]);
        } else {
          console.error('Unexpected response format:', data);
          message.error('Formato de respuesta inesperado');
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, [restaurantId]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const showWorkerModal = async (workerDni: string) => {
    try {
      console.log(`Fetching worker info for DNI: ${workerDni}`);
      const response = await axios.get(`${API_URL}/workers/${workerDni}`);
      console.log('Worker info received:', response.data);
      setWorkerInfo(response.data);
      setIsWorkerModalVisible(true);
    } catch (error) {
      console.error('Error fetching worker information:', error);
    }
  };

  const hideWorkerModal = () => {
    setIsWorkerModalVisible(false);
    setWorkerInfo(null);
  };

  const showAspirantsModal = () => {
    setIsAspirantsModalVisible(true);
  };

  const hideAspirantsModal = () => {
    setIsAspirantsModalVisible(false);
  };

  const deleteJob = async (id: number) => {
    try {
      console.log(`Deleting job with id: ${id}`);
      await axios.delete(`${API_URL}/jobs/${id}`);
      setJobData((prevData) => prevData.filter((job) => job.id !== id));
      if (selectedCard?.id === id) {
        setSelectedCard(null);
      }
      message.success('Trabajo borrado exitosamente');
    } catch (error) {
      console.error('Error deleting job:', error);
      message.error('Error al borrar el trabajo');
    }
  };

  const finishJob = async (id: number) => {
    try {
      console.log(`Finalizing job with id: ${id}`);

      // Mark the job as finished in the backend
      await axios.put(`${API_URL}/jobs/finish/${id}`);

      //Get the job details
      const jobDetails = await axios.get(`${API_URL}/api/job-details/${id}`);
      const finishedJobDetails = jobDetails.data;

      const response = await fetch(`${API_URL}/worker-name/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch worker Name');
      }
      const workerData = await response.json();
      console.log("Worker data", workerData);
      setFullname(workerData.fullname);
      console.log("Worker name",fullname);

      //retrieve the stripe account ID
      const getWorkerStripeAccountID = await axios.get(`${API_URL}/workers/stripe-account/fullname/${fullname}`);
      console.log("Worker stripe account ID", getWorkerStripeAccountID.data[0].stripe_account_id);
      const stripeID = await getWorkerStripeAccountID.data[0].stripe_account_id;
      setWorkerStripeAccountID(stripeID);
      console.log("Worker stripe account ID", workerStripeAccountID);

      const paymentIntentResponse = await fetch(`${API_URL}/create-payment-intent-worker`,{
        method:"POST",
        headers: { "Content-Type": "application/json" },
        body:JSON.stringify({
          payment:parseFloat(finishedJobDetails[0].job_payment),
          startHour:finishedJobDetails[0].job_start_hour,
          endHour:finishedJobDetails[0].job_end_hour,
          stripe_account_id:getWorkerStripeAccountID.data[0].stripe_account_id
        })
      });

      const paymentIntent = await paymentIntentResponse.json();
      console.log("Im payment intent",paymentIntent);

      if(!paymentIntent.clientSecret) {
        throw new Error("No client secret returned");
      }
      console.log("Im the client secret",paymentIntent.clientSecret);
      setCurrentClientSecret(paymentIntent.clientSecret);


      setWorkerPayModal(true);

      setIsSurveyPanelVisible(true);
      
      // Update the job data locally to reflect the changes
      setJobData((prevData) =>
        prevData.map((job) =>
          job.id === id ? { ...job, job_status: false, job_finished: true } : job
        )
      );
  
      if (selectedCard?.id === id) {
        setSelectedCard({ ...selectedCard, job_status: false, job_finished: true });
      }
  
      message.success('Trabajo finalizado exitosamente');

    } catch (error) {
      console.error('Error finalizing job:', error);
      message.error('Error al finalizar el trabajo, intentelo de nuevo');
    }
  };  

  const handlePaymentSuccess = () => {
    setWorkerPayModal(false);
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return 'Fecha no disponible';
    const date = addHours(parseISO(dateString), 1);
    return format(date, "d 'de' MMMM 'de' yyyy", { locale: es });
  };

  const formatTime = (timeString: string) => {
    if (!timeString) return 'Hora no disponible';
    const [hour, minute, second] = timeString.split(':');
    if (hour === undefined || minute === undefined || second === undefined) return 'Hora no disponible';
    const date = new Date();
    date.setHours(parseInt(hour), parseInt(minute), parseInt(second));
    return format(date, 'hh:mm a', { locale: es });
  };

  const cardStyles: React.CSSProperties = {
    background: colorBgContainer,
    borderRadius: borderRadiusLG,
    border: `1px solid ${colorBorder}`,
    position: 'relative',
    padding: '16px',
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedJobData = jobData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="container">
      <h2 className="heading">Administra tus trabajos creados</h2>
      <Divider />
      <Row gutter={[16, 16]} style={{ flex: 1, overflow: 'hidden' }}>
        <Col span={10} className="card-list">
          {jobData.length === 0 ? (
            <p>No hay trabajos creados</p>
          ) : (
            <>
              {paginatedJobData.map((job) => (
                <div key={job.id} className="card-item">
                  <ContentCard
                    title={job.job_title || 'No Title'}
                    description={job.job_description || 'No Description'}
                    onClick={() => {
                      console.log('Card clicked:', job);
                      setSelectedCard(job);
                      handleCardClick(job);
                    }}
                  />
                </div>
              ))}
              <Pagination
                className="pagination-custom"
                current={currentPage}
                pageSize={pageSize}
                total={jobData.length}
                onChange={handlePageChange}
                style={{ marginTop: '16px', textAlign: 'center' }}
              />
            </>
          )}
        </Col>
        <Col span={14} className="details-column">
          <div className="card-details" style={cardStyles}>
            {selectedCard ? (
              <>
                <div>
                  <h2>{selectedCard.job_title || 'No Title'} - {restaurantName}</h2>
                  <Divider />
                  <p>{selectedCard.job_description || 'No Description'}</p>
                  <p>Fecha de inicio: {formatDate(selectedCard.job_start_date)}</p>
                  <p>Fecha de fin: {formatDate(selectedCard.job_end_date)}</p>
                  <p>Hora de inicio: {formatTime(selectedCard.job_start_hour)}</p>
                  <p>Hora de fin: {formatTime(selectedCard.job_end_hour)}</p>
                  <p>Sueldo: {selectedCard.job_payment} €</p>
                  <p>Estado: {selectedCard.job_status ? 'Activo' : 'Inactivo'}</p>
                  <p>Finalizado: {selectedCard.job_finished ? 'Sí' : 'No'}</p>
                </div>
                <div className="button-group">
                  {selectedCard.worker_dni && selectedCard.job_status == false? (
                    <Button
                      type="primary"
                      size="large"
                      className="card-button"
                      onClick={() => showWorkerModal(selectedCard.worker_dni)}
                    >
                      Ver Trabajador
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      size="large"
                      className="card-button"
                      onClick={showModal}
                    >
                      Ver Aplicantes
                    </Button>
                  )}
                  <Button
                    type="default"
                    size="large"
                    className="edit-button"
                    onClick={() => finishJob(selectedCard.id)}
                  >
                    Finalizar Trabajo
                  </Button>
                  <Popconfirm
                    title="¿Estás seguro de que deseas borrar este trabajo?"
                    onConfirm={() => deleteJob(selectedCard.id)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <Button
                      type="default"
                      size="large"
                      className="edit-button"
                    >
                      Borrar Trabajo
                    </Button>
                  </Popconfirm>
                </div>
              </>
            ) : (
              <p>Selecciona un trabajo para consultar los detalles y ver los aspirantes</p>
            )}
          </div>
          <div className="aspirants-button">
            <Button className='btn' type="primary" size="large" onClick={showAspirantsModal} disabled={!selectedCard}>
              Buscar aspirantes
            </Button>
          </div>

        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onCancel={hideModal}
        footer={null}
      >
        <UserListView jobId={selectedCard?.id} />
      </Modal>
      <Modal
        open={isWorkerModalVisible}
        onCancel={hideWorkerModal}
        footer={null}
      >
        <WorkerUserInfo visible={isWorkerModalVisible} onClose={hideWorkerModal} user={workerInfo} />
      </Modal>
      <Modal
        open={isAspirantsModalVisible}
        onCancel={hideAspirantsModal}
        footer={null}
        title="Busca aspirantes para tu empleo"
        styles={{ body: { maxHeight: '70vh', overflowY: 'auto' } }}
      >
        <WorkersList jobId={selectedCard?.id}/>
      </Modal>
      <Modal
        title="¡Cuéntanos tu experiencia!"
        open={isSurveyPanelVisible}
        onCancel={() => setIsSurveyPanelVisible(false)}
        footer={null}
      >
        <WorkerSurveyPanel onCancel={() => setIsSurveyPanelVisible(false)} userFullName={fullname} restaurantName={restaurantName} userType={userType} />
      </Modal>
      <Modal
      title="Pago al trabajador"
      open={workerPayModal}
      onCancel={() => setWorkerPayModal(false)}
      closable={false}
      footer={null}>
        <PayWorker 
        clientSecret={currentClientSecret} 
        CONNECTED_ACCOUNT_ID={workerStripeAccountID}
        onPaymentSuccess={handlePaymentSuccess} />
      </Modal>
    </div>
  );
};

export default ManageJobsLayout;
