import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  HomeOutlined,
  NotificationOutlined,
  BuildOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, theme, Divider, Row, Col, Modal, message, Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';
import axios from 'axios';
import WorkerProfileView from './worker/workerProfileView';
import NotificationView from './worker/notificationView';
import BussinessProfileView from './bussiness/bussinessProfileView';
import HomeContent from './worker/homeContent';
import ManageJobsLayout from './bussiness/manageJobsLayout';
import JobForm from './bussiness/jobForm';
import useUserLocation from '../hooks/useUserLocation';
import './styles/homePageStyle.css';
import './styles/logo.css';
// components
import WorkerForRestaurantSurveyPanel from './worker/surveyForWorkerReviewingRestaurantPanel';
//assets
import logo from '../assets/kurro-black-logo.jpeg';
import { API_URL } from '../config/credentials';

const { Header, Sider, Content } = Layout;

interface JobData {
  id: number;
  worker_dni: string | null;
  job_title: string;
  job_description: string;
  job_start_date: string;
  job_end_date: string;
  job_start_hour: string;
  job_end_hour: string;
  job_payment: number;
  job_status: boolean;
  restaurant_id: number;
}

const HomePageLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState('1');
  const [selectedJob, setSelectedJob] = useState<JobData | null>(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [userType, setUserType] = useState<string>('');
  const [fullname, setFullname] = useState<string>('');
  const [isReady, setIsReady] = useState<boolean>(false);
  const [jobData, setJobData] = useState<JobData[]>([]);
  const [allJobs, setAllJobs] = useState<JobData[]>([]);
  const [isUrgentFilterActive, setIsUrgentFilterActive] = useState(false);
  const { token } = theme.useToken();
  const { colorBgContainer, borderRadiusLG, colorBorder } = token;
  const navigate = useNavigate();
  const { location } = useUserLocation();
  const [urgent, setUrgent] = useState(false);
  const [notifications, setNotifications] = useState<string[]>([]);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [workerHasPendingReviews, setWorkerHasPendingReviews] = useState(false);
  const [reviewId, setReviewId] = useState<number | null>(null);
  //const [isModalVisible, setIsModalVisible] = useState(false);

  const addNotification = (notification: string) => {
    setNotifications((prevNotifications) => {
      const exists = prevNotifications.some((n) => n === notification);
      return exists ? prevNotifications : [...prevNotifications, notification];
    });
  };

  const fetchJobById = useCallback(async (jobId: number) => {
    try {
      const response = await axios.get(`${API_URL}/jobs/${jobId}`);
      const updatedJob = response.data;

      // Update the job in the jobData state
      setJobData((prevJobData) =>
        prevJobData.map((job) => (job.id === jobId ? updatedJob : job))
      );

      // Update the selected job if it is the one being updated
      if (selectedJob && selectedJob.id === jobId) {
        setSelectedJob(updatedJob);
      }

      console.log(`Job ${jobId} updated successfully`, updatedJob);
    } catch (error) {
      console.error(`Error fetching updated job ${jobId}:`, error);
    }
  }, [selectedJob]);

  const checkForUrgentJobs = useCallback(
    async (jobs: JobData[]) => {
      console.log("Checking for urgent jobs...");
      const currentDateTime = new Date();
      console.log("Current date and time:", currentDateTime);

      const foundUrgentJobs = jobs.filter((job) => {
        if (!job.job_start_date || !job.job_start_hour) {
          console.warn(`Job ${job.id} is missing date or hour information.`);
          return false;
        }

        const datePart = job.job_start_date.split('T')[0]; // Extract only the date part
        const combinedDateTimeString = `${datePart}T${job.job_start_hour}`;
        const jobStartDateTime = new Date(combinedDateTimeString);
        console.log(`Combined job start date and time for job ${job.id}:`, jobStartDateTime);

        const difference = differenceInMinutes(jobStartDateTime, currentDateTime);
        console.log(`Difference in minutes for job ${job.id}:`, difference);

        return difference <= 120 && difference >= 0; // Ensure the job starts in the future but within 2 hours
      });

      console.log("Urgent jobs found:", foundUrgentJobs);

      if (foundUrgentJobs.length > 0) {
        addNotification('Hay trabajos urgentes disponibles.');
        setHasNotifications(true);

        // Update payment for urgent jobs and fetch updated job data
        for (const job of foundUrgentJobs) {
          try {
            const response = await axios.put(`${API_URL}/jobs/update-payment/${job.id}`);
            console.log(`Payment updated for job ${job.id}:`, response.data);

            // Fetch updated job data
            await fetchJobById(job.id);
          } catch (error) {
            console.error(`Error updating payment for job ${job.id}:`, error);
          }
        }
      }
    },
    [fetchJobById]
  );

  const fetchPendingReviews = useCallback(async (workerId: string) => {
    try {
      const response = await axios.get(`${API_URL}/reviews/workerTorestaurant/${workerId}`);
      const pendingReview = response.data;
      const {id} = pendingReview;
      console.log("ID de la review:", id);
  
      if (pendingReview && pendingReview.id) {
        console.log("There is a pending review");
        localStorage.setItem('pendingReviewId', id);
        setReviewId(id);
        console.log("ID de la review en el localstorage:",localStorage.getItem('pendingReviewId'));
        setWorkerHasPendingReviews(true);
        addNotification('Tienes una/s encuesta/s nueva sin atender');
      }
      console.log('Pending review:', pendingReview);
    } catch (error) {
      console.error('Error fetching pending review:', error);
    }
  }, [addNotification]);  

  useEffect(() => {
    const type = localStorage.getItem('userType');
    if (type) {
      setUserType(type);
    }

    const fetchFullName = async () => {
      const storedUserId = localStorage.getItem('userId');

      if (storedUserId && type) {
        try {
          const endpoint =
            type === 'restaurant'
              ? `${API_URL}/restaurants/${storedUserId}`
              : `${API_URL}/workers/${storedUserId}`;
          const response = await axios.get(endpoint);
          const userData = response.data;
          setFullname(type === 'restaurant' ? userData.company_name : userData.fullname);
          setIsReady(true);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }

      // Notificaciones
      if (type === 'restaurant') {
        const jobApplicationsEndpoint = `${API_URL}/job_applications/restaurant/${storedUserId}`;
        const jobApplicationsResponse = await axios.get(jobApplicationsEndpoint);
        const applications = jobApplicationsResponse.data;
        if (applications.length > 0) {
          const newNotification = `Tienes ${applications.length} nueva(s) solicitud(es) de trabajo.`;
          addNotification(newNotification);
          setHasNotifications(true);
        }
      }
    };
    fetchFullName();
  }, [fetchPendingReviews]);

  useEffect(() => {
    if (userType === 'worker') {
      const fetchJobs = async () => {
        try {
          const response = await axios.get(`${API_URL}/jobs/available`);
          const data = response.data;
          console.log('Jobs data:', data);

          if (Array.isArray(data)) {
            // Filter and add only available jobs
            const availableJobs = data.filter((job) => job.job_status === 1);

            if (availableJobs.length > 0) {
              console.log("First available job:", availableJobs[0]);
              setAllJobs(availableJobs);
              setJobData(availableJobs);
              if (!selectedJob) setSelectedJob(availableJobs[0]);
              // Check for urgent jobs
              checkForUrgentJobs(data);
            } else {
              setAllJobs([]);
              setJobData([]);
              setSelectedJob(null);
            }
          } else if (typeof data === 'object' && data !== null) {
            // Handle single job object case
            if (data.job_status === 1) {
              setAllJobs([data]);
              setJobData([data]);
              setSelectedJob(data);
            } else {
              setAllJobs([]);
              setJobData([]);
              setSelectedJob(null);
            }
            // Check for urgent jobs
            checkForUrgentJobs([data]);
          } else {
            console.error('Unexpected response format:', data);
          }
        } catch (error) {
          console.error('Error fetching jobs:', error);
        }
      };
      const storedUserId = String(localStorage.getItem('userId'));
      fetchPendingReviews(storedUserId);


      fetchJobs();
    }
  }, [userType, checkForUrgentJobs, selectedJob]);

  const handleJobClick = useCallback((job: JobData) => {
    setSelectedJob(job);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    localStorage.removeItem('locationObtained'); // Clear the flag on logout
    navigate('/login');
  };

  const handleConfirmCancel = () => setIsConfirmVisible(false);

  const handleReserveJob = async () => {
    if (!selectedJob) return;

    const storedUserId = localStorage.getItem('userId');
    if (!storedUserId) {
      message.error('No se pudo encontrar el ID del usuario.');
      return;
    }

    try {
      const response = await axios.post('https://backend.kurro.es/job_applications', {
        worker_dni: storedUserId,
        restaurant_id: selectedJob.restaurant_id,
        job_id: selectedJob.id,
      });
      if (response.status === 201) {
        message.success('Solicitud de trabajo enviada con éxito.');
      } else {
        message.error('Error al enviar la solicitud de trabajo.');
      }
    } catch (error) {
      console.error('Error sending job application:', error);
      message.error('Error al enviar la solicitud de trabajo.');
    } finally {
      setIsConfirmVisible(false);
    }
  };

  const menuItems = useMemo(() => {
    const commonItems = [
      {
        key: '1',
        icon: userType === 'restaurant' ? <BuildOutlined /> : <HomeOutlined />,
        label: userType === 'restaurant' ? 'Mis trabajos' : 'Inicio',
      },
      {
        key: '2',
        icon: (
          <Badge dot className='antd-badge' count={hasNotifications ? notifications.length : 0} offset={[5, 5]}>
            <NotificationOutlined />
          </Badge>
        ),
        label: 'Notificaciones',
      },
    ];

    if (userType === 'worker') {
      return [
        ...commonItems,
        {
          key: '3',
          icon: <UserOutlined />,
          label: 'Perfil',
        },
      ];
    } else if (userType === 'restaurant') {
      return [
        ...commonItems,
        {
          key: '4',
          icon: <UserOutlined />,
          label: 'Perfil de restaurante',
        },
      ];
    } else {
      return commonItems;
    }
  }, [userType, notifications, hasNotifications]);

  const renderContent = useMemo(() => {
    const storedRestaurantId = localStorage.getItem('userId');
    switch (selectedKey) {
      case '1':
        return userType === 'restaurant' ? (
          <ManageJobsLayout
            handleCardClick={handleJobClick}
            colorBgContainer={colorBgContainer}
            borderRadiusLG={borderRadiusLG as unknown as string}
            colorBorder={colorBorder}
            restaurantName={fullname}
            restaurantId={parseInt(storedRestaurantId ?? '0', 10)}
          />
        ) : (
          <HomeContent
            colorBgContainer={colorBgContainer}
            borderRadiusLG={borderRadiusLG as unknown as string}
            colorBorder={colorBorder}
            fullname={fullname}
            handleJobClick={handleJobClick}
            jobData={jobData}
            setJobData={setJobData}
            allJobs={allJobs}
            setAllJobs={setAllJobs}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            urgent={urgent}
            setUrgent={setUrgent}
            checkForUrgentJobs={checkForUrgentJobs}
            setIsConfirmVisible={setIsConfirmVisible} // Pass this prop
          />
        );
      case '2':
        return (
          <div style={{ position: 'relative', height: '100%' }}>
            <h1>Notificaciones</h1>
            <NotificationView userType={userType} data={notifications} hasNotifications={hasNotifications} />
          </div>
        );
      case '3':
        return <WorkerProfileView onFullnameChange={(name) => { setFullname(name); setIsReady(true); }} />;
      case '4':
        return <BussinessProfileView onFullnameChange={(name) => { setFullname(name); setIsReady(true); }} />;
      
      default:
        return null;
    }
  }, [selectedKey, selectedJob, handleJobClick, colorBgContainer, borderRadiusLG, colorBorder, userType, fullname, isReady, jobData, urgent, notifications, hasNotifications, checkForUrgentJobs, allJobs]);

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} className="custom-sider">
        <div className="demo-logo-vertical">
          <img 
            src={logo} 
            alt="Logo"
            className="logo-image"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          selectedKeys={[selectedKey]}
          onClick={(e) => setSelectedKey(e.key)}
          items={menuItems}
        />
        <Menu
          theme="dark"
          mode="inline"
          style={{ height: '100%' }}
          selectable={false}
        >
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
          >
            Cerrar sesión
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="layout-container">
        <Header style={{ padding: 0 }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
                className="toggle-button-icon"
              />
            </Col>
            <Col>
              <span className="Nombre completo del usuario">{fullname}</span>
            </Col>
          </Row>
        </Header>
        <Content className="content-container">
          {renderContent}
        </Content>
      </Layout>
      <Modal
        title="Confirmación"
        open={isConfirmVisible}
        footer={null}
        onCancel={handleConfirmCancel}
      >
        <p>¿Está seguro de que desea aplicar al trabajo {selectedJob?.job_title}?</p>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={handleConfirmCancel} style={{ marginRight: 8 }}>
            No
          </Button>
          <Button type="primary" onClick={handleReserveJob}>
            Sí
          </Button>
        </div>
      </Modal>
      <Modal
        title="¡Cuéntanos tu experiencia!"
        open={workerHasPendingReviews}
        onCancel={() => setWorkerHasPendingReviews(false)}
        footer={null}
      >
        {reviewId !== null && (
          <WorkerForRestaurantSurveyPanel
            onCancel={() => setWorkerHasPendingReviews(false)}
            setWorkerHasPendingReviews={setWorkerHasPendingReviews}
            reviewId={reviewId} // Pass the review ID
          />
        )}
      </Modal>
    </Layout>
  );
};

export default HomePageLayout;
