import HomePageLayout from "../components/homePageLayout";

function HomePage() {
    return (
        <div>
            <HomePageLayout />
        </div>
    )
}

export default HomePage;