// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
  }
  
  .register-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  
  .register-form h1 {
    margin-bottom: 1rem;
  }
  
  .register-form .ant-btn {
    width: 100%;
  }
  
  .dropdown-container {
    margin-bottom: 1.5rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/pagesStyle/registerPageStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,WAAW;IACX,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".register-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;\n    background-color: #f0f2f5;\n    padding: 20px;\n  }\n  \n  .register-form {\n    background-color: white;\n    padding: 2rem;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    max-width: 600px;\n    text-align: center;\n  }\n  \n  .register-form h1 {\n    margin-bottom: 1rem;\n  }\n  \n  .register-form .ant-btn {\n    width: 100%;\n  }\n  \n  .dropdown-container {\n    margin-bottom: 1.5rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
