// styles
import 'mapbox-gl/dist/mapbox-gl.css';
// components
import Map, { NavigationControl, Marker, GeolocateControl } from 'react-map-gl';
// credentials
import { MAPBOX_PUBLIC_TOKEN } from '../config/credentials';
// Ensure you have the path to the pin image correct
import pin from '../assets/pin.png';
//hooks
import { useEffect, useState } from 'react';

function CustomMap({ latitude, longitude }) {
  const [viewState, setViewState] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 25
  });

  useEffect(() => {
    if (latitude && longitude) {
      setViewState({
        longitude: longitude,
        latitude: latitude,
        zoom: 15
      });
    }
  }, [latitude, longitude]);

  return (
    <div style={{ height: '280px', width: '1000px' }}>
      <Map
        mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
        {...viewState}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        style={{ width: '100%', height: '100%' }}
        onMove={evt => setViewState(evt.viewState)}
      >
        <Marker
          longitude={longitude}
          latitude={latitude}
          anchor="bottom"
        >
          <img src={pin} alt="Marker" width={30} height={30} />
        </Marker>
        <GeolocateControl />
        <NavigationControl showCompass showZoom visualizePitch />
      </Map>
    </div>
  );
}

export default CustomMap;
