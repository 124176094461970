import React, { useState } from "react";
import WorkerUserForm from "../components/workerUserForm";
import BussinessUserForm from "../components/bussinessUserForm";
import DropDownMenu from "../components/dropDown";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./pagesStyle/registerPageStyles.css";

const RegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const [isUser, setIsUser] = useState<boolean | null>(null);

  const onClickLogin = () => {
    navigate("/login");
  };

  const handleSelect = (selected: string) => {
    if (selected === 'Soy estudiante o free lancer') {
      setIsUser(true);
    } else if (selected === 'Soy un negocio') {
      setIsUser(false);
    }
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <h1>Registro</h1>
        <div className="dropdown-container">
          <DropDownMenu onSelect={handleSelect} />
        </div>
        {isUser !== null && (
          isUser ? (
            <div>
              <WorkerUserForm />
            </div>
          ) : (
            <div>
              <BussinessUserForm />
            </div>
          )
        )}
        <Button onClick={onClickLogin}>Iniciar sesión</Button>
      </div>
    </div>
  );
};

export default RegisterPage;
