import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaySection from './PaySection';
import { REACT_APP_STRIPE_PUBLIC_KEY } from '../../../config/credentials';

export default function PayWorker({ clientSecret, CONNECTED_ACCOUNT_ID, onPaymentSuccess }) {
  const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY, { locale: 'es' });  

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <PaySection onPaymentSuccess={onPaymentSuccess} />
    </Elements>
  );
}