//Mapbox
export const MAPBOX_PUBLIC_TOKEN = 'pk.eyJ1Ijoia3Vycm8iLCJhIjoiY2x3dG9namxlMDYwajJycWxjdGNweHFvMyJ9.XqHzC66jBzpMpjhdyk0FoA';

//Cloudinary
export const CLOUDINARY_CLOUD_NAME = 'dqisfpvin';
export const CLOUDINARY_API_KEY = '43f_yvPyMgdZmQ2Mpcc_FT0GcIg';
export const CLOUDINARY_API_SECRET = '43f_yvPyMgdZmQ2Mpcc_FT0GcIg';

//Stripe
export const REACT_APP_STRIPE_PUBLIC_KEY="pk_test_51PMDZIClI74StKofW5mCASerBYFKFOKd0SLGdKDG0ydiBjxTx59QQ7tz3taHKlRiA4zXPh8rplKOJHwjQgWFIbJe00MDXtrCop";
export const REACT_APP_STRIPE_PRIVATE_KEY="sk_test_51PMDZIClI74StKofPjHt2QRLmNWgJmkCf5BUvHPNnnwrt8xSXUBgrc39fPzqoAnling2TQmmt65urTAxu1shux0Q00Zi9kwZzG";


//Api backend url
export const API_URL = 'https://backend.kurro.es';