import React from 'react';
import { Avatar, Rate } from 'antd';

interface UserInfoProps {
  visible: boolean;
  onClose: () => void;
  user: {
    fullname?: string;
    profile_picture?: string;
    age?: number;
    phone?: string;
    email?: string;
    rating?: number;
    worker_resume?: string;
  } | null;
}

const UserInfo: React.FC<UserInfoProps> = ({ visible, onClose, user }) => {
  if (!visible || !user) {
    return null;
  }

  const { fullname, profile_picture, age, phone, email, rating, worker_resume } = user;

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Avatar src={profile_picture ?? '/default-avatar.png'} size={100} />
      <h2>{fullname ?? 'Nombre no disponible'}</h2>
      <p>Edad: {age ?? 'No disponible'}</p>
      <p>Teléfono: {phone ?? 'No disponible'}</p>
      <p>Correo electrónico: {email ?? 'No disponible'}</p>
      <p>Calificación: <Rate disabled defaultValue={rating ?? 0} /></p>
      <p>Descripción: {worker_resume ?? 'No disponible'}</p>
    </div>
  );
};

export default UserInfo;
