import React, { useState, useEffect } from 'react';
import { Descriptions, Input, Button, message, Modal, Rate } from 'antd';
import axios from 'axios';
import AvatarUploader from '../avatarUploader';
import JobForm from './jobForm';
import CustomMap from '../customMap';
import '../styles/bussinessProfileStyles.css';
//utilities
import { API_URL} from '../../config/credentials';

const { TextArea } = Input;

interface BussinessProfileViewProps {
  onFullnameChange: (name: string) => void;
}

const BussinessProfileView: React.FC<BussinessProfileViewProps> = ({ onFullnameChange }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [originalImageUrl, setOriginalImageUrl] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [originalCompanyName, setOriginalCompanyName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [originalPhone, setOriginalPhone] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [originalContactEmail, setOriginalContactEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [originalAddress, setOriginalAddress] = useState<string>('');
  const [restaurantType, setRestaurantType] = useState<string>('');
  const [originalRestaurantType, setOriginalRestaurantType] = useState<string>('');
  // Rating
  const [restaurantRating, setRestaurantRating] = useState<number>(0);
  const [originalRestaurantRating, setOriginalRestaurantRating] = useState<number>(0);
  const [restaurantDescription, setRestaurantDescription] = useState<string>('');
  const [originalRestaurantDescription, setOriginalRestaurantDescription] = useState<string>('');
  const [resLatitude, setResLatitude] = useState<number>(0);
  const [resLongitude, setResLongitude] = useState<number>(0);

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    console.log("State of the modal",isModalVisible);
    const fetchRestaurantData = async () => {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId) {
        try {
          const response = await axios.get(`${API_URL}/restaurants/${storedUserId}`);
          const restaurantData = response.data;
          setImageUrl(restaurantData.profile_picture);
          setOriginalImageUrl(restaurantData.profile_picture);
          setCompanyName(restaurantData.company_name);
          setOriginalCompanyName(restaurantData.company_name);
          setPhone(restaurantData.phone);
          setOriginalPhone(restaurantData.phone);
          setContactEmail(restaurantData.email);
          setOriginalContactEmail(restaurantData.email);
          setAddress(restaurantData.res_address);
          setOriginalAddress(restaurantData.res_address);
          setRestaurantType(restaurantData.res_type);
          setOriginalRestaurantType(restaurantData.res_type);
          setRestaurantDescription(restaurantData.res_description);
          setOriginalRestaurantDescription(restaurantData.res_description);
          setResLatitude(restaurantData.res_latitude);
          setResLongitude(restaurantData.res_longitude);

          // Fetch and set the average rating
          const ratingResponse = await axios.get(`${API_URL}/reviews/restaurant/average/${restaurantData.company_name}`);
          const averageRating = parseFloat(ratingResponse.data.average_rating);
          setRestaurantRating(averageRating);
          setOriginalRestaurantRating(averageRating);

          onFullnameChange(restaurantData.company_name);
        } catch (error) {
          console.error('Error fetching restaurant data:', error);
          message.error('Failed to fetch restaurant data. Please try again.');
        }
      }
    };

    fetchRestaurantData();
  }, [onFullnameChange]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    const storedUserId = localStorage.getItem('userId');

    try {
      const updateData = {
        profile_picture: imageUrl,
        company_name: companyName,
        phone,
        contact_email: contactEmail,
        address,
        restaurant_type: restaurantType,
        restaurant_rating: restaurantRating,
        restaurant_description: restaurantDescription,
        res_latitude: resLatitude,
        res_longitude: resLongitude,
      };

      const response = await axios.put(`${API_URL}/restaurants/${storedUserId}`, updateData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        message.success('Profile updated successfully');
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Failed to update profile. Please try again.');
    }
  };

  const handleCancel = () => {
    setImageUrl(originalImageUrl);
    setCompanyName(originalCompanyName);
    setPhone(originalPhone);
    setContactEmail(originalContactEmail);
    setAddress(originalAddress);
    setRestaurantType(originalRestaurantType);
    setRestaurantRating(originalRestaurantRating);
    setRestaurantDescription(originalRestaurantDescription);
    setIsEditing(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCreateJob = (values: any) => {
    console.log('Job values:', values);
    setIsModalVisible(false);
    message.success('Job created successfully');
  };

  return (
    <>
      <Descriptions title="Información del Restaurante" layout="vertical" column={1}>
        <Descriptions.Item label="Foto de la empresa">
          <AvatarUploader imageUrl={imageUrl} setImageUrl={setImageUrl} isEditing={isEditing} />
        </Descriptions.Item>
        <Descriptions.Item label="Nombre de la empresa">
          <span className="text-style">{companyName}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Teléfono">
          <span className="text-style">{phone}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Correo de contacto">
          <span className="text-style">{contactEmail}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Domicilio del restaurante">
          {isEditing ? (
            <TextArea 
              value={address} 
              onChange={(e) => setAddress(e.target.value)} 
              style={{ resize: 'none' }} 
            />
          ) : (
            <span className="text-style">{address}</span>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Tipo de restaurante">
          <span className="text-style">{restaurantType}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Descripción del restaurante">
          {isEditing ? (
            <TextArea 
              value={restaurantDescription} 
              onChange={(e) => setRestaurantDescription(e.target.value)} 
              style={{ resize: 'none' }} 
            />
          ) : (
            <span className="text-style">{restaurantDescription}</span>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Dirección del restaurante">
          <span className="text-style">{address}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Calificación del restaurante">
          <Rate disabled value={restaurantRating} />
        </Descriptions.Item>
        <Descriptions.Item label="Ubicación del restaurante">
          <CustomMap latitude={resLatitude} longitude={resLongitude} />
        </Descriptions.Item>
      </Descriptions>
      <div className="button-container">
        <div className="left-button-container">
          <Button className='left-button' disabled={isEditing}>Ver encuestas</Button>
          <Button className="left-button" onClick={showModal} disabled={isEditing}>Crear trabajo</Button>
        </div>
        <div className="right-button-container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button className="right-button" onClick={handleEdit} style={{ marginRight: '10px' }}>
            Editar perfil
          </Button>
          {isEditing && (
            <>
              <Button className="right-button" onClick={handleCancel} style={{ marginRight: '10px' }}>
                Cancelar
              </Button>
              <Button className="right-button-save" type="primary" onClick={handleSave}>
                Guardar cambios
              </Button>
            </>
          )}
        </div>
      </div>

      <Modal
        title="Cree un nuevo trabajo"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <JobForm onCreate={handleCreateJob} onClose={() => setIsModalVisible(false)} />
      </Modal>
    </>
  );
};

export default BussinessProfileView;
