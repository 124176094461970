import React, { useState } from 'react';
import axios from 'axios';
import type { FormProps } from 'antd';
import { Button, Form, Input, InputNumber, Select, message } from 'antd';
import { API_URL } from '../config/credentials';

type FieldType = {
  dni: string;
  stripe_account_id:string;
  email: string;
  university: string;
  age: number;
  fullName: string;
  user_password: string;
  phone: string;
  address?: string;
  bankAccount: string;
};

const { Option } = Select;

function WorkerUserForm() {

  const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {

  try {
    const stripeAccount = await axios.post(`${API_URL}/register-stripe-account`,{
      email:values.email
    });
    const {id} = stripeAccount.data;
    console.log("This is the strippe account id: ",id);
    const stripeAccountId = id;
  
    const response = await axios.post(`${API_URL}/workers`, {
      dni: values.dni,
      stripe_account_id: stripeAccountId,
      email: values.email,
      user_password: values.user_password, // Ensure it matches the expected name
      bank_account: values.bankAccount, // Ensure it matches the expected name
      phone: values.phone,
      university: values.university,
      age: values.age,
      fullname: values.fullName, // Ensure it matches the expected name
      home_address: values.address, // Ensure it matches the expected name
    });
  
    fetch(`${API_URL}/account_link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        account: stripeAccountId,
      }),
    })
    .then((response) => response.json())
    .then((json) => {
      setAccountLinkCreatePending(false);
      const { url, error } = json;
      if (url) {
        window.location.href = url;
        }
      });
        
      message.success('Cuenta creada de manera exitosa!');
      console.log('Success:', response.data);
    } catch (error) {
      message.error('Error en la creación de cuenta.');
      console.log('Error:', error);
    }
  };
  
  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="34">+34</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 1000 }}
        initialValues={{ prefix: '34' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="DNI"
          name="dni"
          rules={[{ required: true, message: 'Aún no has ingresado un DNI' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Correo electrónico"
          name="email"
          rules={[{ required: true, message: 'Aún no has ingresado un correo electrónico' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Universidad de procedencia"
          name="university"
          rules={[{ required: true, message: 'Aún no has ingresado tu universidad de procedencia' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Edad"
          name="age"
          rules={[{ required: true, message: 'Aún no has ingresado tu edad' }]}
        >
          <InputNumber min={1} max={150} style={{ width: '100%' }} step={1} />
        </Form.Item>

        <Form.Item<FieldType>
          label="Nombre completo"
          name="fullName"
          rules={[{ required: true, message: 'Aún no has ingresado tu nombre completo' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Contraseña"
          name="user_password"
          rules={[{ required: true, message: 'Aun no has ingresado una contraseña' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<FieldType>
          label="Dirección"
          name="address"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          name="phone"
          label="Teléfono"
          rules={[{ required: true, message: 'Aún no has ingresado tu número de teléfono' }]}
        >
          <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item<FieldType>
          name="bankAccount"
          label="Cuenta bancaria"
          rules={[{ required: true, message: 'Aún no has ingresado una cuenta bancaria' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default WorkerUserForm;

