import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Rate, message } from 'antd';
import axios from 'axios';
import { API_URL } from '../../config/credentials';

const { TextArea } = Input;

interface WorkerFormValues {
  companyName: string;
  userName: string;
  title: string;
  description: string;
  rating: number;
}

interface WorkerForRestaurantSurveyPanelProps {
  onCancel: () => void;
  setWorkerHasPendingReviews: React.Dispatch<React.SetStateAction<boolean>>;
  reviewId: number; // New prop for review ID
}

interface Review {
  id: string;
  reviewer: string;
  reviewed: string;
  answeared: boolean;
  stars: number | null;
  review: string | null;
}

const WorkerForRestaurantSurveyPanel: React.FC<WorkerForRestaurantSurveyPanelProps> = ({ onCancel, setWorkerHasPendingReviews, reviewId }) => {
  const [form] = Form.useForm();
  const [review, setReview] = useState<Review | null>(null);

  useEffect(() => {
    console.log("Modal opened");

    const fetchReviewData = async (reviewId: number) => {
      try {
        const response = await axios.get(`${API_URL}/reviews/restaurant/${reviewId}`);
        const reviewData = response.data;
        setReview(reviewData);
        form.setFieldsValue({
          companyName: reviewData.reviewed,
          userName: reviewData.reviewer,
        });
      } catch (error) {
        console.error('Error fetching review data:', error);
      }
    };

    if (reviewId) {
      fetchReviewData(reviewId);
    } else {
      console.log("No pending reviews found.");
    }
  }, [form, reviewId]);

  const onFinish = async (values: WorkerFormValues) => {
    if (!review) {
      message.error("No se encontró información");
      return;
    }

    try {
      // Make a PUT request to update the review
      const response = await axios.put(`${API_URL}/reviews/restaurant/${review.id}`, {
        stars: values.rating,
        review: values.description,
      });

      if (response.status === 200) {
        message.success("Encuesta terminada, gracias por tu tiempo!");

        // Remove the handled review ID from local storage
        localStorage.removeItem('pendingReviewId');

        // Reset the form fields
        form.resetFields();
        onCancel();

        // Update state to indicate there are no more pending reviews
        setWorkerHasPendingReviews(false); // Update parent state
      } else {
      }
    } catch (error) {
    }
  };

  return (
    <Form
      form={form}
      name="worker_survey_form"
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 600, margin: '0 auto' }}
    >
      <Form.Item
        label="Nombre de la Empresa/Negocio:"
        name="companyName"
        rules={[{ required: true, message: 'Por favor, ingrese el nombre de la empresa/negocio' }]}
      >
        <Input disabled />
      </Form.Item>
      
      <Form.Item
        label="Nombre del trabajador:"
        name="userName"
        rules={[{ required: true, message: 'Por favor, ingrese su nombre completo' }]}
      >
        <Input disabled />
      </Form.Item>
      
      <Form.Item
        label="Título de la reseña:"
        name="title"
        rules={[{ required: true, message: 'Por favor, ingrese un título' }]}
      >
        <Input />
      </Form.Item>
      
      <Form.Item
        label="Descripción de la reseña:"
        name="description"
        rules={[{ required: true, message: 'Por favor, ingrese una descripción' }]}
      >
        <TextArea rows={4} style={{ resize: 'none' }} />
      </Form.Item>
      
      <Form.Item
        label="Calificación"
        name="rating"
        rules={[{ required: true, message: 'Por favor, ingrese una calificación' }]}
      >
        <Rate />
      </Form.Item>
      
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Enviar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WorkerForRestaurantSurveyPanel;
