import React from 'react';
import { Avatar, Rate } from 'antd';

interface UserInfoProps {
  visible: boolean;
  onClose: () => void;
  user: {
    name: {
      title?: string;
      first: string;
      last: string;
    };
    picture?: {
      large?: string;
    };
    dob?: {
      age?: number;
    };
    phone: string;
    email: string;
    rating: number;
    description?: string;
  } | null;
}

const UserInfo: React.FC<UserInfoProps> = ({ visible, onClose, user }) => {
  if (!visible || !user) {
    return null;
  }

  const { name, picture, dob, phone, email, rating, description } = user;

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Avatar src={picture?.large ?? '/default-avatar.png'} size={100} />
      <h2>{name ? `${name.first} ${name.last}` : 'Nombre no disponible'}</h2>
      <p>Edad: {dob?.age ?? 'No disponible'}</p>
      <p>Teléfono: {phone ?? 'No disponible'}</p>
      <p>Correo electrónico: {email ?? 'No disponible'}</p>
      <p>Calificación: <Rate disabled defaultValue={rating ?? 0} /></p>
      <p>Descripción: {description ?? 'No disponible'}</p>
    </div>
  );
};

export default UserInfo;
