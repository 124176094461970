import React, { useState } from 'react';
import { Row, Col, Divider, Checkbox, Pagination, Button, message } from 'antd';
import SelectBox from '../selectBox';
import ContentCard from '../contentCard';
import { differenceInMinutes, format, parseISO, addHours } from 'date-fns';
import { es } from 'date-fns/locale';
import axios from 'axios';
import { API_URL } from '../../config/credentials';

interface JobData {
  id: number;
  worker_dni: string | null;
  job_title: string;
  job_description: string;
  job_start_date: string;
  job_end_date: string;
  job_start_hour: string;
  job_end_hour: string;
  job_payment: number;
  job_status: boolean;
  restaurant_id: number;
}

interface HomeContentProps {
  colorBgContainer: string;
  borderRadiusLG: string;
  colorBorder: string;
  fullname: string;
  handleJobClick: (job: JobData) => void;
  jobData: JobData[];
  setJobData: React.Dispatch<React.SetStateAction<JobData[]>>;
  allJobs: JobData[];
  setAllJobs: React.Dispatch<React.SetStateAction<JobData[]>>;
  selectedJob: JobData | null;
  setSelectedJob: React.Dispatch<React.SetStateAction<JobData | null>>;
  urgent: boolean;
  setUrgent: React.Dispatch<React.SetStateAction<boolean>>;
  checkForUrgentJobs: (jobs: JobData[]) => void;
  setIsConfirmVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HomeContent: React.FC<HomeContentProps> = ({
  colorBgContainer,
  borderRadiusLG,
  colorBorder,
  fullname,
  handleJobClick,
  jobData,
  setJobData,
  allJobs,
  setAllJobs,
  selectedJob,
  setSelectedJob,
  urgent,
  setUrgent,
  checkForUrgentJobs,
  setIsConfirmVisible, // Use this prop
}) => {
  const pageSize = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [isUrgentFilterActive, setIsUrgentFilterActive] = useState(false);

  const handleUrgentChange = (e: any) => {
    const isChecked = e.target.checked;
    setIsUrgentFilterActive(isChecked);

    if (isChecked) {
      // Show only urgent jobs
      const currentDateTime = new Date();
      const urgentJobs = allJobs.filter(job => {
        const datePart = job.job_start_date.split('T')[0];
        const combinedDateTimeString = `${datePart}T${job.job_start_hour}`;
        const jobStartDateTime = new Date(combinedDateTimeString);
        const difference = differenceInMinutes(jobStartDateTime, currentDateTime);
        return difference <= 120 && difference >= 0; // Within 2 hours
      });
      setJobData(urgentJobs);
    } else {
      // Show all jobs
      setJobData(allJobs);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedJobData = jobData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handleDistanceChange = async (value: string) => {
    const distanceMap: { [key: string]: number } = {
      '1': 10,
      '2': 5,
      '3': 1,
      '4': 0, // Todos
    };

    const distance = distanceMap[value];

    if (!navigator.geolocation) {
      message.error('Geolocation is not supported by your browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        if (distance === 0) {
          // Fetch all jobs
          try {
            const response = await axios.get(`${API_URL}/jobs`);
            setJobData(response.data);
          } catch (error) {
            console.error('Error fetching jobs:', error);
          }
        } else {
          // Fetch jobs within the specified distance
          try {
            console.log('Fetching jobs within', distance, 'km of', { latitude, longitude });
            const response = await axios.get(`${API_URL}/jobs/search?lat=${latitude}&lng=${longitude}&distance=${distance}&urgent=${urgent}`);
            console.log('The nearest jobs to you are:', response.data);
            setJobData(response.data);
          } catch (error) {
            console.error('Error fetching jobs:', error);
          }
        }
      },
      (error) => {
        console.error('Error obtaining location:', error);
        message.error('Error al obtener localización');
      }
    );
  };

  const formatDate = (dateString: string) => {
    const date = addHours(parseISO(dateString), 1);
    return format(date, "d 'de' MMMM 'de' yyyy", { locale: es });
  };

  const formatTime = (timeString: string) => {
    const [hour, minute, second] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hour), parseInt(minute), parseInt(second));
    return format(date, 'hh:mm a', { locale: es });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <h1>Encuentra el trabajo ideal para ti</h1>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SelectBox onChangeValue={handleDistanceChange} />
        <Checkbox onChange={handleUrgentChange} style={{ marginLeft: 10 }}>
          Urgente
        </Checkbox>
      </div>
      <Divider />
      <h2>Explora los empleos disponibles y recientes cerca de ti...</h2>
      <br />
      <Row gutter={[16, 16]}>
        <Col span={10} style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 220px)' }}>
          {jobData.length === 0 ? (
            <p>No hay trabajos disponibles</p>
          ) : (
            paginatedJobData.map((job) => (
              <div key={job.id} style={{ marginBottom: '24px' }}>
                <ContentCard
                  title={job.job_title}
                  description={job.job_description}
                  onClick={() => handleJobClick(job)}
                />
              </div>
            ))
          )}
          <Pagination
            className="pagination-custom"
            current={currentPage}
            pageSize={pageSize}
            total={jobData.length}
            onChange={handlePageChange}
            style={{ marginTop: '16px', textAlign: 'center' }}
          />
        </Col>
        <Col span={14}>
          <div
            style={{
              padding: '16px',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              border: `1px solid ${colorBorder}`,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {selectedJob ? (
              <>
                <div>
                  <h2>{selectedJob.job_title}</h2>
                  <Divider />
                  <p>{selectedJob.job_description}</p>
                  <p>Fecha de inicio: {formatDate(selectedJob.job_start_date)}</p>
                  <p>Fecha de fin: {formatDate(selectedJob.job_end_date)}</p>
                  <p>Hora de inicio: {formatTime(selectedJob.job_start_hour)}</p>
                  <p>Hora de fin: {formatTime(selectedJob.job_end_hour)}</p>
                  <p>Sueldo: {selectedJob.job_payment} € / hr</p>
                  <p>Estado: {selectedJob.job_status ? 'Activo' : 'Inactivo'}</p>
                </div>
                <Button
                  type="primary"
                  size="large"
                  className="button-red"
                  style={{
                    alignSelf: 'center',
                    fontSize: '18px',
                    padding: '0 24px',
                    height: '40px',
                  }}
                  onClick={() => setIsConfirmVisible(true)}
                >
                  Reservar
                </Button>
              </>
            ) : (
              <p>Selecciona un trabajo para ver los detalles</p>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HomeContent;
