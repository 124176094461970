import React, { useRef } from 'react';
import { Avatar, Button } from 'antd';
import { EditOutlined, UserOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import "./styles/avatarStyles.css"

interface AvatarUploaderProps {
  imageUrl: string | null;
  setImageUrl: (url: string | null) => void;
  isEditing: boolean;
}

const AvatarUploader: React.FC<AvatarUploaderProps> = ({ imageUrl, setImageUrl, isEditing }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'mtcpq2sd'); // Your Cloudinary upload preset

      try {
        const response = await axios.post('https://api.cloudinary.com/v1_1/dqisfpvin/image/upload', formData);
        const imageUrl = response.data.secure_url;
        setImageUrl(imageUrl);
        console.log('New Image URL:', imageUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleDeleteImage = () => {
    setImageUrl(null);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {imageUrl ? <Avatar src={imageUrl} size={100} /> : <Avatar size={100} icon={<UserOutlined />} />}
      {isEditing && (
        <>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            className="avatarButton"
            style={{ position: 'absolute', bottom: 0, right: 0 }}
            onClick={() => fileInputRef.current?.click()}
          />
          {imageUrl && (
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              className="avatarButton"
              style={{ position: 'absolute', bottom: 0, left: 0 }}
              onClick={handleDeleteImage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AvatarUploader;
