import React from "react";
import type { FormProps } from 'antd';
import { Button, Form, Input, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../config/credentials";

type FieldType = {
  email: string;
  password: string;
};

function LoginUserForm() {
  const navigate = useNavigate();

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      const response = await axios.post(`${API_URL}/login`, values);
      console.log("Response data", response.data);
      const { userId, userType } = response.data;
      localStorage.setItem('userId', userId);
      localStorage.setItem('userType', userType);
      // Confirmación de almacenamiento en localStorage
      const storedUserId = localStorage.getItem('userId');
      console.log('User ID stored in localStorage:', storedUserId);
      const storedUserType = localStorage.getItem('userType');
      console.log('User Type stored in localStorage:', storedUserType);
      message.success('Login successful!');
      navigate('/home');
    } catch (error) {
      // Manejo mejorado de errores
      if (axios.isAxiosError(error) && error.response) {
        console.error('Login failed:', error.response.data);
        message.error(error.response.data.message || 'Login failed. Please check your credentials and try again.');
      } else {
        console.error('Login failed:', error);
        message.error('Login failed. Please check your credentials and try again.');
      }
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Please complete the form correctly.');
  };

  const onClickRegister = () => {
    navigate("/register");
  };

  return (
    <div className="login-form-container">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="login-form"
      >
        <Form.Item<FieldType>
          label="Correo"
          name="email"
          rules={[
            { required: true, message: 'Aún no has ingresado un correo electrónico' },
            { type: 'email', message: 'Por favor ingresa un correo electrónico válido' }
          ]}
          className="login-form-item"
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Contraseña"
          name="password"
          rules={[
            { required: true, message: 'Aún no has ingresado tu contraseña' },
            { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }
          ]}
          className="login-form-item"
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" className="login-submit-button">
            Enter
          </Button>
        </Form.Item>
      </Form>
      <div className="register-link">
        <p>Don't have an account?</p>
        <a onClick={onClickRegister} style={{ color: 'blue', cursor: 'pointer' }}>Register now!</a>
      </div>
    </div>
  );
}

export default LoginUserForm;
