// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* bussinessProfileStyles.css */

.text-style {
    text-indent: 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .left-button-container {
    display: flex;
    justify-content: flex-start;
  }

  .right-button-container {
    display: flex;
    justify-content: flex-start;
  }

  .right-button:hover {
    border-color: var(--color-red) !important;
    color: var(--color-red) !important;
  }

  .right-button-save {
    background-color: var(--color-red);
  }

  .right-button-save:hover {
    background-color: white !important;
    border-color: var(--color-red) !important;
    color: var(--color-red) !important;
  }
  
  .left-button {
    margin-right: 10px;
  }

  .left-button:hover {
    border-color: var(--color-red) !important;
    color: var(--color-red) !important;
  }
  
  .modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/styles/bussinessProfileStyles.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;IACI,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,2BAA2B;EAC7B;;EAEA;IACE,yCAAyC;IACzC,kCAAkC;EACpC;;EAEA;IACE,kCAAkC;EACpC;;EAEA;IACE,kCAAkC;IAClC,yCAAyC;IACzC,kCAAkC;EACpC;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,yCAAyC;IACzC,kCAAkC;EACpC;;EAEA;IACE,+BAA+B;IAC/B,gBAAgB;EAClB","sourcesContent":["/* bussinessProfileStyles.css */\n\n.text-style {\n    text-indent: 20px;\n  }\n  \n  .button-container {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n  \n  .left-button-container {\n    display: flex;\n    justify-content: flex-start;\n  }\n\n  .right-button-container {\n    display: flex;\n    justify-content: flex-start;\n  }\n\n  .right-button:hover {\n    border-color: var(--color-red) !important;\n    color: var(--color-red) !important;\n  }\n\n  .right-button-save {\n    background-color: var(--color-red);\n  }\n\n  .right-button-save:hover {\n    background-color: white !important;\n    border-color: var(--color-red) !important;\n    color: var(--color-red) !important;\n  }\n  \n  .left-button {\n    margin-right: 10px;\n  }\n\n  .left-button:hover {\n    border-color: var(--color-red) !important;\n    color: var(--color-red) !important;\n  }\n  \n  .modal-body {\n    max-height: calc(100vh - 200px);\n    overflow-y: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
