import React from 'react';
import { Avatar, List, Rate } from 'antd';

const data = [
  {
    title: 'Zarpado Studios comentó en tu perfil:',
    rating: 4,
  },
  {
    title: 'Roblox comentó en tu perfil:',
    rating: 5,
  },
  {
    title: 'Carolina Herrera comentó en tu perfil:',
    rating: 3,
  },
  {
    title: 'Ing. Hernández comentó en tu perfil:',
    rating: 4,
  },
];

const ReviewPanel: React.FC = () => (
  <List
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item, index) => (
      <List.Item key={index}>
        <List.Item.Meta
          avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
          title={<a href="https://ant.design">{item.title}</a>}
          description={
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta tenetur saepe pariatur quam, neque iusto alias molestias nulla quaerat error possimus eum distinctio sapiente, libero amet laboriosam earum quas reprehenderit.</p>
              <Rate disabled value={item.rating} />
            </div>
          }
        />
      </List.Item>
    )}
  />
);

export default ReviewPanel;
