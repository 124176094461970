import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton, message, Modal } from 'antd';
import axios from 'axios';
import UserInfo from './userInfo';
import { API_URL } from '../../config/credentials';
import '../styles/workerListStyles.css';

interface DataType {
  dni: string;
  phone: string;
  fullname: string;
  email: string;
  profile_picture: string;
  rating: number;
  worker_resume?: string;
}
interface WorkersListProps {
  jobId?: number;
}

const WorkersList: React.FC<WorkersListProps> = ({ jobId }) => {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [list, setList] = useState<DataType[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<DataType | null>(null);

  const fetchWorkers = async () => {
    try {
      const response = await axios.get(`${API_URL}/workers`);
      console.log('Workers data:', response.data);
      setData(response.data);
      setList(response.data);
      setInitLoading(false);
    } catch (error) {
      console.error('Error fetching workers:', error);
      message.error('Error al obtener los trabajadores');
      setInitLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkers();
  }, []);

  const onLoadMore = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/workers`);
      console.log('More workers data:', response.data);
      const newData = data.concat(response.data);
      setData(newData);
      setList(newData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching more workers:', error);
      message.error('Error al obtener más trabajadores');
      setLoading(false);
    }
  };

  const handleItemClick = (item: DataType) => {
    console.log('Item clicked:', item);
    setSelectedUser(item);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    console.log('Modal closed');
    setModalVisible(false);
    setSelectedUser(null);
  };

  const handleSelectClick = async () => {
    if (selectedUser && jobId) {
      console.log('Sending data to server:', {
        worker_dni: selectedUser.dni,
        job_status: true
      });
      try {
        // Update the job
        await axios.put(`${API_URL}/jobs/${jobId}`, {
          worker_dni: selectedUser.dni,
          job_status: false
        });
        message.success(`Trabajador ${selectedUser.fullname} seleccionado exitosamente`);

        // Delete all job applications for the job
        await axios.delete(`${API_URL}/job_applications/job/${jobId}`);
        message.success('Todas las solicitudes de trabajo han sido eliminadas');

        setModalVisible(false);
      } catch (error) {
        console.error('Error updating job or deleting job applications:', error);
        message.error('Error updating job or deleting job applications');
      }
    }
  };

  const mapUserToUserInfo = (user: DataType | null) => {
    if (!user) return null;

    const nameParts = user.fullname.split(' ');
    const first = nameParts.slice(0, -1).join(' ');
    const last = nameParts.slice(-1).join(' ');

    const mappedUser = {
      name: {
        title: '',
        first: first || '',
        last: last || ''
      },
      picture: {
        large: user.profile_picture || ''
      },
      dob: {
        age: user.rating ?? 0
      },
      phone: user.phone || '',
      email: user.email || '',
      rating: user.rating ?? 0,
      description: user.worker_resume || ''
    };

    console.log('Mapped user:', mappedUser);
    return mappedUser;
  };

  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore}>Cargar más</Button>
      </div>
    ) : null;

  return (
    <>
      <List
        className="loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={list}
        renderItem={(item) => (
          <List.Item onClick={() => handleItemClick(item)}>
            <Skeleton avatar title={false} loading={initLoading} active>
              <List.Item.Meta
                avatar={<Avatar src={item.profile_picture || 'default-avatar.png'} />}
                title={item.fullname}
                description={null}
              />
            </Skeleton>
          </List.Item>
        )}
      />
      <Modal
        open={modalVisible}
        title="Perfil del usuario"
        footer={null}
        onCancel={handleModalClose}
      >
        <UserInfo visible={modalVisible} onClose={handleModalClose} user={mapUserToUserInfo(selectedUser)} />
        {jobId && (
          <div className="modal-footer-button">
            <Button type="primary" className='select-worker-button' onClick={handleSelectClick}>
              Seleccionar trabajador
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default WorkersList;