import React from 'react';
import { Select } from 'antd';

interface SelectBoxProps {
  onChangeValue: (value: string) => void;
}

const SelectBox: React.FC<SelectBoxProps> = ({ onChangeValue }) => (
  <Select
    showSearch
    style={{ width: '20%' }}
    placeholder="Selecciona una distancia..."
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    defaultValue="4"
    onChange={onChangeValue}
    options={[
      { value: '1', label: '10 km' },
      { value: '2', label: '5 km' },
      { value: '3', label: '1 km' },
      { value: '4', label: 'Todos' },
    ]}
  />
);

export default SelectBox;