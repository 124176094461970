import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Rate, message } from 'antd';
import { API_URL } from '../config/credentials';

const { TextArea } = Input;

interface FormValues {
  companyName: string;
  userName: string;
  title: string;
  description: string;
  rating: number;
}

interface WorkerSurveyPanelProps {
  onCancel: () => void;
  userFullName: string;
  restaurantName: string;
  userType: string;
}

const WorkerSurveyPanel: React.FC<WorkerSurveyPanelProps> = ({ onCancel, userFullName, restaurantName, userType }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ userName: userFullName, companyName: restaurantName });
  }, [userFullName, restaurantName, form]);

  const onFinish = async (values: FormValues) => {
    console.log('Form values:', values);
    const { companyName, userName, description, rating } = values;

    try {
        // Call your API endpoint for worker reviews   
        const response = await fetch(`${API_URL}/reviews/worker`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            reviewer: companyName,
            reviewed:userName,
            stars: rating,
            review: description,
            submitted: true, // or set based on your form data
          }),
        });

        if (!response.ok) {
          throw new Error('Hubo en error al enviar la encuesta');
        }

        const data = await response.json();
        message.success('Encuesta exitosa!');
      
    } catch (error) {
      console.error('Error submitting review:', error);
    }
    try {
      const response = await fetch(`${API_URL}/reviews/restaurant`,{
        method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            reviewer: userName,
            reviewed:companyName,
          }),
      });
    } catch(error) {
      console.error('Error submitting review:', error);
    }

    // Reset the form fields

    //form.resetFields();
    onCancel();
  };

  return (
    <Form
      form={form}
      name="worker_survey_form"
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 600, margin: '0 auto' }}
    >
      <Form.Item
        label="Nombre de la Empresa/Negocio:"
        name="companyName"
        rules={[{ required: true, message: 'Por favor, ingrese el nombre de la empresa/negocio' }]}
      >
        <Input disabled />
      </Form.Item>
      
      <Form.Item
        label="Nombre del trabajador:"
        name="userName"
        rules={[{ required: true, message: 'Por favor, ingrese su nombre completo' }]}
      >
        <Input disabled />
      </Form.Item>
      
      <Form.Item
        label="Título de la reseña:"
        name="title"
        rules={[{ required: true, message: 'Por favor, ingrese un título' }]}
      >
        <Input />
      </Form.Item>
      
      <Form.Item
        label="Descripción de la reseña:"
        name="description"
        rules={[{ required: true, message: 'Por favor, ingrese una descripción' }]}
      >
        <TextArea rows={4} style={{ resize: 'none' }} />
      </Form.Item>
      
      <Form.Item
        label="Calificación"
        name="rating"
        rules={[{ required: true, message: 'Por favor, ingrese una calificación' }]}
      >
        <Rate />
      </Form.Item>
      
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Enviar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WorkerSurveyPanel;