import React, { useEffect, useState } from 'react';
import { Form, Input, DatePicker, Button, Row, Col, message } from 'antd';
import axios from 'axios';
import { API_URL, REACT_APP_STRIPE_PUBLIC_KEY } from '../../config/credentials';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from '../payment/CheckOutForm';

const { TextArea } = Input;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY, { locale: 'es' });

interface JobFormProps {
  onCreate: (values: any) => void;
  onClose: () => void;
}

const JobForm: React.FC<JobFormProps> = ({ onCreate, onClose }) => {
  const [form] = Form.useForm();
  const [clientSecret, setClientSecret] = useState();
  const [paymentIntentId, setPaymentIntentId] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [jobCreated, setJobCreated] = useState<boolean>(false);

  useEffect(() => {
    const restaurantId = localStorage.getItem('userId');
    if (restaurantId) {
      form.setFieldsValue({ restaurant_id: restaurantId });
    } else {
      message.error('No se encontró el ID del restaurante en el almacenamiento local.');
    }
  }, [form]);

  const handleSubmit = async (values: any) => {
    const startHour = `${values.startHourHours}:${values.startHourMinutes}:00`;
    const endHour = `${values.endHourHours}:${values.endHourMinutes}:00`;
    if (isNaN(values.startHourHours) || isNaN(values.startHourMinutes) || isNaN(values.endHourHours) || isNaN(values.endHourMinutes)) {
      message.error('Horas de inicio o final inválidas');
      return;
    }
    try {
      const paymentIntentResponse = await fetch(`${API_URL}/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          payment: parseFloat(values.jobPay), 
          startHour: values.startHourHours, 
          endHour: values.endHourHours 
        }),
      });

      const paymentIntentData = await paymentIntentResponse.json();

      if (!paymentIntentData.clientSecret) {
        throw new Error('No client secret returned');
      }

      setClientSecret(paymentIntentData.clientSecret);
      setPaymentIntentId(paymentIntentData.clientSecret.split('_secret_')[0]);
      
    } catch (error) {
      console.error('Error creating payment intent:', error);
      message.error('Error al crear el intento de pago');
      setError('Error al crear el intento de pago');
      return;
    }
  };

  const createJob = async () => {
    if (jobCreated) return;

    const values = form.getFieldsValue();
    const startHour = `${values.startHourHours}:${values.startHourMinutes}:00`;
    const endHour = `${values.endHourHours}:${values.endHourMinutes}:00`;

    try {
      const response = await axios.post(`${API_URL}/jobs`, {
        restaurant_id: values.restaurant_id,
        job_title: values.jobTitle,
        job_description: values.jobDescription,
        job_start_date: values.startDate.format('YYYY-MM-DD'),
        job_end_date: values.endDate.format('YYYY-MM-DD'),
        job_start_hour: startHour,
        job_end_hour: endHour,
        job_payment: parseFloat(values.jobPay),
        job_status: true,
        job_finished: false
      });

      if (response.status === 201) {
        message.success(response.data.message);
        onCreate(values);
        form.resetFields();
        setJobCreated(true);
      } else {
        message.error(response.data.error);
      }
    } catch (error) {
      console.error('Error creating job:', error);
      message.error('Error al crear el trabajo');
      setError('Error al crear el trabajo');
    }
  };

  const options = {
    clientSecret
  };
  const validatePositiveNumber = (_: any, value: number) => {
    if (value < 0) {
      return Promise.reject(new Error('El valor no puede ser negativo'));
    }
    return Promise.resolve();
  };

  const validateEndDate = (_: any, value: any) => {
    const startDate = form.getFieldValue('startDate');
    if (startDate && value && value.isBefore(startDate)) {
      return Promise.reject(new Error('La fecha de finalización no puede ser antes de la fecha de inicio'));
    }
    return Promise.resolve();
  };

  // Función de validación personalizada para asegurarse de que el valor sea 8.28 o mayor
  const validateMinimumPay = (_:any, value:number) => {
    if (value >= 8.28) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('El sueldo por hora debe ser al menos 8.28€'));
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="restaurant_id"
          label="ID del Restaurante"
          rules={[{ required: true, message: 'Por favor ingrese el ID del restaurante' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="jobTitle"
          label="Título del trabajo"
          rules={[{ required: true, message: 'Por favor ingrese el título del trabajo' }]}
        >
          <Input placeholder="Ejemplo: Cocinero, Mesero" />
        </Form.Item>
        <Form.Item
          name="jobDescription"
          label="Descripción del trabajo"
          rules={[{ required: true, message: 'Por favor ingrese la descripción del trabajo' }]}
        >
          <TextArea rows={4} style={{ resize: 'none' }} placeholder="Ejemplo: Responsable de la cocina, atención al cliente" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Fecha de inicio del trabajo"
              rules={[{ required: true, message: 'Por favor seleccione la fecha de inicio del trabajo' }]}
            >
              <DatePicker placeholder='Seleccionar fecha' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="endDate"
              label="Fecha de finalización del trabajo"
              rules={[
                { required: true, message: 'Por favor seleccione la fecha de finalización del trabajo' },
                { validator: validateEndDate }
              ]}
            >
              <DatePicker placeholder='Seleccionar fecha' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Hora de inicio"
              required
            >
              <Input.Group compact>
                <Form.Item
                  name="startHourHours"
                  noStyle
                  rules={[
                    { required: true, message: 'Por favor ingrese la hora de inicio' },
                    { validator: validatePositiveNumber }
                  ]}
                >
                  <Input style={{ width: '50%' }} placeholder="Horas" type="number" />
                </Form.Item>
                <Form.Item
                  name="startHourMinutes"
                  noStyle
                  rules={[
                    { required: true, message: 'Por favor ingrese los minutos de inicio' },
                    { validator: validatePositiveNumber }
                  ]}
                >
                  <Input style={{ width: '50%' }} placeholder="Minutos" type="number" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Hora final"
              required
            >
              <Input.Group compact>
                <Form.Item
                  name="endHourHours"
                  noStyle
                  rules={[
                    { required: true, message: 'Por favor ingrese la hora de finalización' },
                    { validator: validatePositiveNumber }
                  ]}
                >
                  <Input style={{ width: '50%' }} placeholder="Horas" type="number" />
                </Form.Item>
                <Form.Item
                  name="endHourMinutes"
                  noStyle
                  rules={[
                    { required: true, message: 'Por favor ingrese los minutos de finalización' },
                    { validator: validatePositiveNumber }
                  ]}
                >
                  <Input style={{ width: '50%' }} placeholder="Minutos" type="number" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="jobPay"
          label="Sueldo (hr)"
          rules={[
            { required: true, message: 'Por favor ingrese el sueldo del trabajo' },
            { validator: validateMinimumPay }
            
          ]}
        >
          <Input type="number" step="0.01" placeholder="8.28" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Continuar</Button>
        </Form.Item>
      </Form>
      {error && <div className="error-message">{error}</div>}
      
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} onSuccess={createJob} onClose={onClose} setJobCreated={setJobCreated} jobCreated={jobCreated} />
        </Elements>
      )}
    </div>
  );
};

export default JobForm;
