import React from 'react';
import { Divider, List } from 'antd';

// Define the type for the props
interface NotificationViewProps {
  userType: string;
  data: string[];
  hasNotifications:boolean;
}

// Update the component to accept props
const NotificationView: React.FC<NotificationViewProps> = ({ userType, data,hasNotifications }) => (
  <>
    <Divider orientation="left">{userType}</Divider>
    <List
      size="large"
      bordered
      dataSource={data}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
  </>
);

export default NotificationView;