import React from "react";
import LoginUserForm from "../components/loginUserForm";
import "./pagesStyle/loginPageStyle.css";

const LoginPage: React.FC = () => {
  return (
    <div className="login-container">
      <h1>Login</h1>
      <LoginUserForm />
    </div>
  );
};

export default LoginPage;
