// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --color-red: #E23F40;
    --color-light-gray: #C9D1D8;
    --color-gray: #8D9EAB;
    --color-dark-gray: #3E5461;
    --color-darkest-gray: #213440;
  }

.modal-footer-button {
    text-align: center;
    margin-top: 16px;
  }
  
.select-worker-button {
    background-color: var(--color-red);
}

.select-worker-button:hover {
    border-color: var(--color-red);
    background-color: transparent !important;
    color: var(--color-red) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/workerListStyles.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,2BAA2B;IAC3B,qBAAqB;IACrB,0BAA0B;IAC1B,6BAA6B;EAC/B;;AAEF;IACI,kBAAkB;IAClB,gBAAgB;EAClB;;AAEF;IACI,kCAAkC;AACtC;;AAEA;IACI,8BAA8B;IAC9B,wCAAwC;IACxC,kCAAkC;AACtC","sourcesContent":[":root {\n    --color-red: #E23F40;\n    --color-light-gray: #C9D1D8;\n    --color-gray: #8D9EAB;\n    --color-dark-gray: #3E5461;\n    --color-darkest-gray: #213440;\n  }\n\n.modal-footer-button {\n    text-align: center;\n    margin-top: 16px;\n  }\n  \n.select-worker-button {\n    background-color: var(--color-red);\n}\n\n.select-worker-button:hover {\n    border-color: var(--color-red);\n    background-color: transparent !important;\n    color: var(--color-red) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
